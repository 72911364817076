import axios from 'axios';
import NProgress from 'nprogress';
import MpEvent from './mpEvent';
const baseURL = `${process.env.REACT_APP_SERVER_URL}/api`;

export const Api = axios.create({
  baseURL,
  withCredentials: true,
});

Api.interceptors.request.use(
  config => {
    NProgress.start();
    return config;
  },
  null,
  { synchronous: true },
);

Api.interceptors.response.use(
  response => {
    NProgress.done();
    return response;
  },
  error => {
    NProgress.done();
    if (error.response?.status === 401) {
      MpEvent.dispatch('Unauthorized');
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  },
);

export const fetcher = (url, args = {}) => Api.get(url, args).then(res => res.data);

export const getRefreshToken = async () => {
  const res = await Api.post('v2/token/refresh');
  return res.data.result;
};

export const loginUser = async credential => {
  const res = await Api.post('v2/login', credential);
  return res.data.result;
};

export const logoutUser = async () => {
  const res = await Api.post('v2/logout');
  return res.data.result;
};

export const addProductMappings = async data => {
  const res = await Api.post('v2/product/add-mappings', data);
  return res.data.result;
};

export const updateProductMappings = async data => {
  const res = await Api.post('v2/product/update-mappings', data);
  return res.data.result;
};

export const updateProductMappingStatusApi = async data => {
  const res = await Api.post('v2/product/update-mapping-status', data);
  return res.data.result;
};

export const deleteProductMappings = async data => {
  const res = await Api.post('v2/product/delete-mappings', data);
  return res.data.result;
};

export const deleteUser = async data => {
  const res = await Api.post('v2/admin/users/delete', data);
  return res.data.result;
};

export const cancelOrder = async orderNo => {
  const res = await Api.post('v2/order/cancel', { orderNo });
  return res.data.result;
};

export const updateStoreStatus = async data => {
  const res = await Api.post('v2/store/update-status', data);
  return res.data.result;
};

export const addProduct = async data => {
  const res = await Api.post('v2/product/create', data);
  return res.data.result;
};

export const getS3Urls = async data => {
  const res = await Api.post('v1/get-s3-urls', data);
  return res.data.result;
};

export const fetchPost = async key => {
  const res = await Api.post(`/v2/store/profile/fetch-post`, { key });
  return res;
};

export const blockUser = async (vendorName, userId) => {
  const res = await Api.post('v2/store/block', { vendorName, userId });
  return res;
};
