import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import AdminLayout from '../../layouts/AdminLayout';
import useSWR from 'swr';
import { fetcher } from '../../utils/api';
import SearchBar from '../../components/SearchBar';
import Header from './Header';
import StoresTable from './StoresTable';
import Loading from '../../components/Loading';

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  results: {
    marginTop: 12,
  },
}));

const Stores = () => {
  const classes = useStyles();
  // Initiate states for stores and filteredStores
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);

  // Fetch stores data
  const { data, mutate: updateStores } = useSWR('v2/stores', fetcher);

  // When data changes, update states
  useEffect(() => {
    if (data?.result) {
      setStores(data.result);
      setFilteredStores(data.result);
    }
  }, [data]);

  // This function will be triggered when the search value changes
  function handleSearch(event) {
    const val = event.target.value;
    setFilteredStores(stores.filter(str => str.name.toLowerCase().includes(val.toLowerCase())));
  }

  return (
    <AdminLayout>
      <Box className={classes.root}>
        <Loading loading={stores.length === 0} />
        <Header />
        {/* Pass handleSearch function to onChange event */}
        <SearchBar onSearch={handleSearch} />
        <StoresTable stores={filteredStores} updateStores={updateStores} />
      </Box>
    </AdminLayout>
  );
};

export default Stores;
