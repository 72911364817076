import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid } from '@material-ui/core';
import AdminLayout from '../../layouts/AdminLayout';
import useSWR from 'swr';
import { fetcher } from '../../utils/api';
import SearchBar from '../../components/SearchBar';
import Header from './Header';
import ProductCard from '../../components/product/ProductCard';
import Loading from '../../components/Loading';

const Products = () => {
  const classes = useStyles();
  const { data } = useSWR('v2/products', fetcher);
  const products = data?.result || [];

  const handleSearch = () => {};

  return (
    <AdminLayout>
      <Box className={classes.root}>
        <Header />
        <SearchBar onSearch={handleSearch} />
        <Loading loading={!data?.result} />
        <Grid container spacing={4}>
          {products.map(product => (
            <Grid item md={4} key={product.id}>
              <ProductCard product={product} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </AdminLayout>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  results: {
    marginTop: 12,
  },
}));

export default Products;
