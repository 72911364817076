import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardMedia, Typography, Divider, Link } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import Page from '../../components/Page/Page';
import gradients from '../../utils/gradients.js';
import LoginForm from '../../components/LoginForm';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(20, 2),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: 12,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: 4,
    position: 'absolute',
    top: -32,
    left: 12,
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: 12,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  person: {
    marginTop: 8,
    display: 'flex',
  },
  avatar: {
    marginRight: 8,
  },
}));

const Login = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Login">
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <LockIcon className={classes.icon} />
          <Typography variant="h3"> Sign in </Typography>

          <LoginForm className={classes.loginForm} />

          <Divider className={classes.divider} />
          <Typography variant="subtitle2">Sign in on the internal platform</Typography>
        </CardContent>
        <CardMedia className={classes.media} image="/images/auth.png" title="Cover">
          <div className={classes.person}>
            <Typography color="inherit" variant="body2">
              {new Date().getFullYear()} All right reserved by FanPal™
            </Typography>
          </div>
        </CardMedia>
      </Card>
    </Page>
  );
};

export default Login;
