import { Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import AdminDashBoard from './pages/dashboard';
import Login from './pages/login';
import Error404 from './pages/404';
import Stores from './pages/store';
import Orders from './pages/order';
import Payments from './pages/payment';
import SampleOrders from './pages/order/SampleOrders';
import StoreView from './pages/store/StoreView';
import OrderView from './pages/order/OrderView';
import PaymentView from './pages/payment/PaymentView';
import Setting from './pages/setting';
import Products from './pages/product';
import ProductView from './pages/product/ProductView';
import StoreProducts from './pages/store-product';
import ProductVariants from './pages/product-variant';
import CreatorPosts from './pages/posts';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />

      <Route path="/dashboard" element={<AdminDashBoard />} />

      <Route path="/stores" element={<Stores />} />
      <Route path="/store/:storeId" element={<StoreView />} />

      <Route path="/payouts" element={<Payments />} />
      <Route path="/payment/:id" element={<PaymentView />} />

      <Route path="/orders" element={<Orders />} />
      <Route path="/sample-orders" element={<SampleOrders />} />
      <Route path="/order/:orderId" element={<OrderView />} />

      <Route path="/products" element={<Products />} />
      <Route path="/products/:id" element={<ProductView />} />

      <Route path="/store-products" element={<StoreProducts />} />
      <Route path="/posts" element={<CreatorPosts />} />
      <Route path="/store/variants" element={<ProductVariants />} />

      <Route path="/setting" element={<Setting />} />

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default AppRoutes;
