import { Box, Fade, Modal, TextField, Typography, Button, Grid } from '@material-ui/core';
import { useRef, useState } from 'react';
import ListInput from '../elements/ListInput';
import Loading from '../Loading';
import Toastr from '@react-uis/toastr';
import { addProduct, getS3Urls } from '../../utils/api';
import { postDataToURL } from '../../utils/s3';

const initialValue = {
  name: '',
  description: '',
  basePrice: '',
  defaultName: '',
  minPrice: '',
  slug: '',
  suggestedPrice: '',
  shippingText: [],
  details: [],
};

const AddNewProductModal = ({ open, onClose }) => {
  const backImageInputRef = useRef();
  const frontImageInputRef = useRef();

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(initialValue);

  const handleChange = e => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  const handleListInputChange = ({ name, value }) => {
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const getImageFromFiles = files => {
    if (files.length > 0) {
      return new Promise(resolve => {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = function () {
          const imageSrc = reader.result.toString() || '';
          resolve(imageSrc);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleSubmit = async () => {
    let frontImageUrl = '';
    let backImageUrl = '';
    const frontImageFiles = frontImageInputRef.current.files;
    const backImageFiles = backImageInputRef.current.files;
    if (frontImageFiles.length === 0) {
      return Toastr.error('Front Image is required.');
    }

    if (!product.name) {
      return Toastr.error('Product name is required.');
    }

    if (!product.defaultName) {
      return Toastr.error('Default name is required.');
    }

    if (!product.slug) {
      return Toastr.error('slug is required.');
    }

    if (!product.suggestedPrice) {
      return Toastr.error('Suggested Price is required.');
    }

    if (!product.basePrice) {
      return Toastr.error('Base Price is required.');
    }

    if (!product.minPrice) {
      return Toastr.error('Minimum Price is required.');
    }

    setLoading(true);

    const s3Urls = await getS3Urls({
      frontImage: 'front-image.png',
      backImage: 'back-image.png',
    });

    await postDataToURL(s3Urls.putUrls.frontImage, frontImageFiles[0]);
    frontImageUrl = s3Urls.getUrls.frontImage;

    if (backImageFiles.length > 0) {
      await postDataToURL(s3Urls.putUrls.frontImage, backImageFiles[0]);
      backImageUrl = s3Urls.getUrls.frontImage;
    }

    const submitData = {
      ...product,
      frontImageUrl,
      backImageUrl,
    };

    await addProduct(submitData);

    setLoading(false);
    setProduct(initialValue);
    onClose();
  };

  return (
    <>
      <Loading loading={loading} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={styles.root}>
            <Typography variant="h3">Add New Product</Typography>
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="name"
                  value={product.name}
                  label="Name"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="defaultName"
                  value={product.defaultName}
                  label="Default Name"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="slug"
                  value={product.slug}
                  label="Slug"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="basePrice"
                  type="number"
                  value={product.basePrice}
                  label="Base Price"
                  fullWidth
                  variant="outlined"
                  multiline={true}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="minPrice"
                  type="number"
                  value={product.minPrice}
                  label="Minimum Price"
                  fullWidth
                  variant="outlined"
                  multiline={true}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="suggestedPrice"
                  type="number"
                  value={product.suggestedPrice}
                  label="Suggested Price"
                  fullWidth
                  variant="outlined"
                  multiline={true}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  name="description"
                  value={product.description}
                  label="Description"
                  fullWidth
                  variant="outlined"
                  multiline={true}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button>Upload Front Image</Button>
                <input ref={frontImageInputRef} type="file" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button>Upload Back Image</Button>
                <input ref={backImageInputRef} type="file" />
              </Grid>
              <Grid item xs={12} md={6}>
                <ListInput
                  name="details"
                  label="Product Details"
                  value={product.details}
                  onChange={handleListInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ListInput
                  name="shippingText"
                  label="Shipping Texts"
                  value={product.shippingText}
                  onChange={handleListInputChange}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', mt: 2 }}>
              <Button color="primary" variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
              <Button variant="contained" onClick={onClose} style={{ marginLeft: 10 }}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddNewProductModal;

const styles = {
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
};
