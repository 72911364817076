import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import AdminLayout from '../../layouts/AdminLayout';
import useSWR from 'swr';
import { fetcher } from '../../utils/api';
import SearchBar from '../../components/SearchBar';
import Header from './Header';
import StoreProductsTable from './StoreProductTable';
import Loading from '../../components/Loading';

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  results: {
    marginTop: 12,
  },
}));

const StoreProducts = () => {
  const classes = useStyles();

  const { data } = useSWR('/v2/admin/store-products', fetcher);
  const products = data?.result;

  return (
    <AdminLayout>
      <Loading loading={!products} />
      <Box className={classes.root}>
        <Header />
        <SearchBar />
        <StoreProductsTable products={products || []} />
      </Box>
    </AdminLayout>
  );
};

export default StoreProducts;
