import AdminLayout from '../../layouts/AdminLayout';
import React from 'react';
import classes from '../../globalcss/dash.module.css';
import { useParams } from 'react-router-dom';
import OrderItem from '../../components/OrderItem/OrderItem';
import useSWR from 'swr';
import { fetcher, updateStoreStatus } from '../../utils/api';
import Loading from '../../components/Loading';
import { Box, Button } from '@material-ui/core';
import Dialog from '../../components/dialog/Dialog';

const StoreView = () => {
  const [address, setAddress] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { storeId } = useParams();

  const { data, mutate } = useSWR('/v2/store/detail/' + storeId, fetcher);
  const store = data?.result?.store;
  const stats = data?.result?.stats;
  const orders = data?.result?.orders;
  const paused = store?.status === 'blocked';

  const handleUpdateStatus = async () => {
    Dialog.confirm({
      description: paused
        ? 'Do you want to really active this item?'
        : 'Do you want to really block this item?',
    }).then(async res => {
      if (res) {
        setLoading(true);
        const status = paused ? 'active' : 'blocked';
        await updateStoreStatus({ storeId: store.id, status });
        await mutate();
        setLoading(false);
      }
    });
  };

  const addAddress = (id, address) => {
    const data = { vendorId: id, address: address };
    fetch(`http://localhost:666/store/add-address`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.ok) {
          setShowModal(false);
          return response.json();
        }
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  if (!store || loading) {
    return <Loading loading={true} />;
  }

  return (
    <AdminLayout>
      <Box className="flexCenter flexCol" sx={{ p: 5 }}>
        <img className={classes.vendorLogo} src={store.logo} alt="" />
        <h1>{store.name}</h1>
        <div className={classes.stats} style={{ margin: 'auto' }}>
          <div style={{ marginRight: '1rem' }}>
            <p>vendor profit</p>
            <h3>${stats.vendorProfit}</h3>
          </div>
          <div style={{ marginRight: '1rem' }}>
            <p>fanpal profit</p>
            <h3>${stats.mpProfit}</h3>
          </div>
          <div style={{ marginRight: '1rem' }}>
            <p>fanpal tax</p>
            <h3>${stats.tax}</h3>
          </div>
          <div>
            <p>total revenue</p>
            <h3>${stats.revenue}</h3>
          </div>
        </div>
        <div
          style={{
            display: 'inline-block',
            marginBottom: '2rem',
            padding: '1rem',
            border: '1px solid black',
          }}
        >
          <>
            <p style={{ marginTop: '0' }}>Phone Number</p>
            <h3>{store.user.phoneNo}</h3>
          </>
          <>
            <p style={{ marginTop: '0' }}>Email</p>
            <h3>{store.user.email}</h3>
          </>

          {store.socialHandles && (
            <>
              {store.socialHandles.instagram && (
                <>
                  <p style={{ marginTop: '0' }}>Instagram</p>
                  <h3>{store.socialHandles.instagram} </h3>
                </>
              )}

              {store.socialHandles.tiktok && (
                <>
                  <p style={{ marginTop: '0' }}>Tiktok</p>
                  <h3>{store.socialHandles.tiktok} </h3>
                </>
              )}

              {store.socialHandles.twitch && (
                <>
                  <p style={{ marginTop: '0' }}>Twitch</p>
                  <h3>{store.socialHandles.twitch} </h3>
                </>
              )}
              {store.socialHandles.twitter && (
                <>
                  <p style={{ marginTop: '0' }}>Twitter</p>
                  <h3>{store.socialHandles.twitter} </h3>
                </>
              )}
              {store.socialHandles.youtube && (
                <>
                  <p style={{ marginTop: '0' }}>Youtube</p>
                  <h3>{store.socialHandles.youtube} </h3>
                </>
              )}
            </>
          )}

          {address && (
            <>
              <p style={{ marginTop: '0' }}>Address</p>
              <h3>{address}</h3>
            </>
          )}
        </div>
        <div>
          <Button variant="contained" onClick={handleUpdateStatus}>
            {paused ? 'Resume Store' : 'Pause Store'}
          </Button>
          <Button variant="contained" onClick={() => setShowModal(true)}>
            {store.address ? 'update address' : 'add address'}
          </Button>
          {showModal && (
            <div className={classes.orderModal} onClick={() => setShowModal(false)}>
              <div className={classes.modalContent} onClick={e => e.stopPropagation()}>
                <h3>Address</h3>
                <textarea
                  onChange={e => setAddress(e.target.value)}
                  style={{
                    width: '300px',
                    height: '200px',
                    padding: '.4rem',
                    marginBottom: '1rem',
                  }}
                />
                <button onClick={() => addAddress(storeId.id, address)}>add</button>
              </div>
            </div>
          )}

          {/* <button onClick={() => console.log('deleting vendor')}>delete</button> */}
        </div>
        <h3>{orders.length > 0 ? 'Orders' : 'No orders yet'}</h3>
        <div className={classes.orders}>
          {orders.length > 0 &&
            orders
              .slice(0)
              .reverse()
              .map((order, idx) => {
                return <OrderItem order={order} key={idx} />;
              })}
        </div>
      </Box>
    </AdminLayout>
  );
};

export default StoreView;
