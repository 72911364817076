import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRefreshToken, loginUser, logoutUser } from '../../utils/api';
import MpEvent from '../../utils/mpEvent';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const INITIAL_AUTH = {
  auth_token: '',
  token_expires_in: '',
  user: null,
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [auth, setAuth] = useState(INITIAL_AUTH);
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [error, setError] = useState(null);

  async function refreshToken() {
    try {
      const newAuth = await getRefreshToken();
      if (newAuth) {
        return setAuth(newAuth);
      }
    } catch (error) {
      // Nothing to do
    }
    resetAuth();
  }

  useEffect(() => {
    MpEvent.on('Unauthorized', () => {
      resetAuth();
    });
  }, []);

  useEffect(() => {
    if (auth.auth_token) {
      if (auth.user.role.includes('admin')) {
        setIsLoggedIn(true);
      } else {
        logout();
        setError("You don't have access to admin access");
      }
    } else {
      refreshToken();
    }
  }, [auth]);

  const resetAuth = () => {
    setAuth(INITIAL_AUTH);
    setError(null);
    setIsLoggedIn(false);
    navigate('/');
  };

  const login = async (phoneNo, password) => {
    setError(null);
    const auth = await loginUser({ phoneNo, password });
    setAuth(auth);
  };

  const logout = async () => {
    try {
      await logoutUser();
    } catch (error) {
      console.error(error);
    } finally {
      resetAuth();
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, auth, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
