import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import cssModule from '../../styles/modules/creatorpoststable.module.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';

import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import GenericMoreButton from '../../components/GenericMoreButton';
import TableEditBar from '../../components/TableEditBar';
import Loading from '../../components/Loading';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { blockUser, fetchPost } from '../../utils/api';

export default function CreatorPostsTable({ className, data }) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [posts, setPosts] = useState([]);
  const [mutatedPosts, setMutatedPosts] = useState([]);
  const [recordsFound, setRecordsFound] = useState(0);
  const [search, setSearch] = useState('');

  React.useEffect(() => {
    setLoading(true);
    if (data?.data) {
      let mutated = data.data.flat(1);

      mutated = data.data
        .flatMap(item => {
          if (item) {
            return Object.values(item);
          }
        })
        .filter(item => item !== undefined);

      const fetched = mutated.map(async item => {
        const post = await fetchPost(item.s3Key);
        if (post.data.success) {
          return {
            ...item,
            checked: false,
            pictureURL: post.data.fileURL,
          };
        }
      });

      Promise.all(fetched).then(dta => {
        setPosts(dta);
        setMutatedPosts(dta);
        setRecordsFound(dta.length);
        setLoading(false);
      });
    }
  }, [data]);

  useEffect(() => {
    let mutatedData = [...posts];
    if (search.length > 0) {
      const mut = mutatedData.filter(post =>
        post.vendorName.toLowerCase().includes(search.toLowerCase()),
      );
      setMutatedPosts(mut);
      setRecordsFound(mut.length);
    } else {
      setMutatedPosts(posts);
      setRecordsFound(posts.length);
    }
  }, [search]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const handleVideoLoadedMetadata = event => {
    event.target.play();
  };

  async function handleBlock(post) {
    setLoading(true);
    try {
      const res = await blockUser(post.vendorName, post.userId);
      if (res.data.success) {
        const mutatedData = posts.map(p => {
          if (p.userId === res.data.userId) {
            return {
              ...p,
              status: res.data.status,
            };
          } else {
            return {
              ...p,
            };
          }
        });
        setPosts(mutatedData);
        setMutatedPosts(mutatedData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Loading loading={loading} />
      <TextField
        variant="outlined"
        placeholder="Search user"
        value={search}
        onChange={e => setSearch(e.target.value)}
        InputProps={{
          style: {
            marginTop: '10px',
            height: '40px',
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography
        style={{ marginBottom: '25px' }}
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        {recordsFound} Records found. Page {page + 1} of {Math.ceil(recordsFound / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader action={<GenericMoreButton />} title="All Posts" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Store</TableCell>
                    <TableCell>Post</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mutatedPosts
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((post, index) => (
                      <TableRow hover key={index}>
                        <TableCell>
                          {' '}
                          <div className={cssModule.nameSection}>
                            <img className={cssModule.vendorImg} src={post.logo} />{' '}
                            <h5 className={cssModule.nameText}>{post.vendorName}</h5>
                          </div>{' '}
                        </TableCell>
                        <TableCell>
                          {' '}
                          {post?.fileType?.includes('image') ? (
                            <img className={cssModule.postPicture} src={post.pictureURL} />
                          ) : (
                            <video
                              className={cssModule.postPicture}
                              src={post.pictureURL}
                              width="70"
                              height="auto"
                              muted
                              autoPlay
                              controls
                              onLoadedMetadata={handleVideoLoadedMetadata}
                            ></video>
                          )}
                        </TableCell>
                        <TableCell>
                          <h5
                            style={
                              post.status === 'blocked' ? { color: 'red' } : { color: 'green' }
                            }
                            className={cssModule.nameText}
                          >
                            {post.status}
                          </h5>
                        </TableCell>
                        <TableCell>
                          <h5 className={cssModule.nameText}>
                            {moment.unix(post.timeStamp / 1000).format('MM-DD-YYYY')}
                          </h5>
                        </TableCell>
                        <TableCell>
                          <div className={cssModule.btnContainer}>
                            <Button
                              disabled={loading}
                              onClick={() => handleBlock(post)}
                              className={cssModule.action}
                            >
                              {post.status === 'active' ? 'Block' : 'Unblock'}
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={recordsFound}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  );
}
