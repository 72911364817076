import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, TextField } from '@material-ui/core';
import AdminLayout from '../../layouts/AdminLayout';
import useSWR from 'swr';
import { fetcher } from '../../utils/api';
import SearchBar from '../../components/SearchBar';
import Header from './Header';

import Loading from '../../components/Loading';
import CreatorPostsTable from './CreatorPostsTable';

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  results: {
    marginTop: 12,
  },
}));

const CreatorPosts = () => {
  const classes = useStyles();

  const { data } = useSWR('/v2/store/all-posts', fetcher);

  return (
    <AdminLayout>
      <Loading loading={!data} />
      <Box className={classes.root}>
        <Header />
        <CreatorPostsTable data={data} />
      </Box>
    </AdminLayout>
  );
};

export default CreatorPosts;
