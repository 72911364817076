import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import getInitials from '../../utils/getInitials';
import ReviewStars from '../../components/ReviewStars';
import GenericMoreButton from '../../components/GenericMoreButton';
import TableEditBar from '../../components/TableEditBar';
import { Else, If, Then } from 'react-if';
import Dialog from '../../components/dialog/Dialog';
import { deleteUser } from '../../utils/api';
import Loading from '../../components/Loading';
import ToastManager from '../../components/toaster/ToastManager';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
}));

const StoresTable = ({ className, stores, updateStores, ...rest }) => {
  const classes = useStyles();
  const [selectedStores, setSelectedStores] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [deleting, setDeleting] = useState(false);

  const handleSelectAll = event => {
    const selectedStores = event.target.checked ? stores?.map(customer => customer.id) : [];
    setSelectedStores(selectedStores);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedStores?.indexOf(id);
    let newSelectedStores = [];

    if (selectedIndex === -1) {
      newSelectedStores = newSelectedStores?.concat(selectedStores, id);
    } else if (selectedIndex === 0) {
      newSelectedStores = newSelectedStores?.concat(selectedStores?.slice(1));
    } else if (selectedIndex === selectedStores?.length - 1) {
      newSelectedStores = newSelectedStores?.concat(selectedStores?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedStores = newSelectedStores?.concat(
        selectedStores?.slice(0, selectedIndex),
        selectedStores?.slice(selectedIndex + 1),
      );
    }

    setSelectedStores(newSelectedStores);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const handleActivate = () => {};

  const handleInactivate = () => {};

  const handleDelete = storeIds => {
    Dialog.delete({
      description: 'Do you want to really delete this item?',
    }).then(async res => {
      if (res) {
        setDeleting(true);
        const selectedStoresData = stores.filter(store => storeIds.includes(store?.id));
        const userIds = selectedStoresData.map(store => store?.user?.id);
        await deleteUser({ storeIds, userIds });
        await updateStores();
        setSelectedStores([]);
        ToastManager.success(selectedStores?.length + ' Stores are deleted!.');
        setDeleting(false);
      }
    });
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Loading loading={deleting} />
      <Typography color="textSecondary" gutterBottom variant="body2">
        {stores?.length} Records found. Page {page + 1} of {Math.ceil(stores?.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader action={<GenericMoreButton />} title="All stores" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedStores?.length === stores?.length}
                        color="primary"
                        indeterminate={
                          selectedStores?.length > 0 && selectedStores?.length < stores?.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Store Name</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Social</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stores.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(store => (
                    <TableRow
                      hover
                      key={store?.id}
                      selected={selectedStores?.indexOf(store?.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedStores?.indexOf(store?.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, store?.id)}
                          value={selectedStores?.indexOf(store?.id) !== -1}
                        />
                      </TableCell>
                      <TableCell>
                        <div className={classes.nameCell}>
                          <Avatar className={classes.avatar} src={store?.logo}>
                            {getInitials(store?.name)}
                          </Avatar>
                          <div>
                            <Link color="inherit" component={RouterLink} to="/" variant="h6">
                              {store?.name}
                            </Link>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell> {store?.user?.firstName} </TableCell>
                      <TableCell> {store?.user?.lastName} </TableCell>
                      <TableCell>
                        {store?.socialHandles?.Twitter && (
                          <div>
                            <b>Twitter: </b> {store?.socialHandles?.Twitter}
                          </div>
                        )}
                        {store?.socialHandles?.YouTube && (
                          <div>
                            <b>Youtube: </b> {store?.socialHandles?.YouTube}
                          </div>
                        )}
                        {store?.socialHandles?.Instagram && (
                          <div>
                            <b>Instagram: </b> {store?.socialHandles?.Instagram}
                          </div>
                        )}
                        {store?.socialHandles?.Twitch && (
                          <div>
                            <b>Twitch: </b> {store?.socialHandles?.Twitch}
                          </div>
                        )}
                        {store?.socialHandles?.TikTok && (
                          <div>
                            <b>TikTok: </b> {store?.socialHandles?.TikTok}
                          </div>
                        )}
                        {store?.socialHandles?.Other && (
                          <div>
                            <b>Other: </b> {store?.socialHandles?.Other}
                          </div>
                        )}
                      </TableCell>
                      <TableCell>{store?.user?.email}</TableCell>
                      <TableCell>{store?.user?.phoneNo}</TableCell>
                      <TableCell>
                        <If condition={store?.user?.status === 'active'}>
                          <Then>
                            <Typography style={{ color: 'green', textTransform: 'capitalize' }}>
                              {store?.user?.status}
                            </Typography>
                          </Then>
                          <Else>
                            <Typography style={{ color: 'red', textTransform: 'capitalize' }}>
                              {store?.user?.status}
                            </Typography>
                          </Else>
                        </If>
                      </TableCell>
                      <TableCell>{store?.createdAt}</TableCell>
                      <TableCell align="right">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={'/store/' + store?.id}
                          variant="outlined"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={stores?.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <TableEditBar
        selected={selectedStores}
        onActivate={handleActivate}
        onInactivate={handleInactivate}
        onDelete={handleDelete}
      />
    </div>
  );
};

StoresTable.propTypes = {
  className: PropTypes.string,
  stores: PropTypes.array,
};

StoresTable.defaultProps = {
  stores: [],
};

export default StoresTable;
