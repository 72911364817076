import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import getInitials from '../../utils/getInitials';
import ReviewStars from '../../components/ReviewStars';
import GenericMoreButton from '../../components/GenericMoreButton';
import TableEditBar from '../../components/TableEditBar';
import { Else, If, Then } from 'react-if';
import Dialog from '../../components/dialog/Dialog';
import { deleteUser } from '../../utils/api';
import Loading from '../../components/Loading';
import ToastManager from '../../components/toaster/ToastManager';
import ProductImageWithDesign from '../../components/ProductImageWithDesign';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
}));

const StoreProductsTable = ({ className, products, updateStores, ...rest }) => {
  const classes = useStyles();
  const [selectedStores, setSelectedStores] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [deleting, setDeleting] = useState(false);

  const handleSelectAll = event => {
    const selectedStores = event.target.checked ? products.map(product => product.id) : [];
    setSelectedStores(selectedStores);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedStores.indexOf(id);
    let newSelectedStores = [];

    if (selectedIndex === -1) {
      newSelectedStores = newSelectedStores.concat(selectedStores, id);
    } else if (selectedIndex === 0) {
      newSelectedStores = newSelectedStores.concat(selectedStores.slice(1));
    } else if (selectedIndex === selectedStores.length - 1) {
      newSelectedStores = newSelectedStores.concat(selectedStores.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedStores = newSelectedStores.concat(
        selectedStores.slice(0, selectedIndex),
        selectedStores.slice(selectedIndex + 1),
      );
    }

    setSelectedStores(newSelectedStores);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const handleActivate = () => {};

  const handleInactivate = () => {};

  const handleDelete = storeIds => {
    Dialog.delete({
      description: 'Do you want to really delete this item?',
    }).then(async res => {
      if (res) {
        setDeleting(true);
        const selectedStoresData = products.filter(store => storeIds.includes(store.id));
        const userIds = selectedStoresData.map(store => store.user?.id);
        await deleteUser({ storeIds, userIds });
        await updateStores();
        setSelectedStores([]);
        ToastManager.success(selectedStores.length + ' Stores are deleted!.');
        setDeleting(false);
      }
    });
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Loading loading={deleting} />
      <Typography color="textSecondary" gutterBottom variant="body2">
        {products.length} Records found. Page {page + 1} of{' '}
        {Math.ceil(products.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader action={<GenericMoreButton />} title="All stores" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedStores.length === products.length}
                        color="primary"
                        indeterminate={
                          selectedStores.length > 0 && selectedStores.length < products.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Store</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Updated At</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(product => (
                    <TableRow
                      hover
                      key={product.id}
                      selected={selectedStores.indexOf(product.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedStores.indexOf(product.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, product.id)}
                          value={selectedStores.indexOf(product.id) !== -1}
                        />
                      </TableCell>
                      <TableCell>
                        {product.store ? (
                          <div className={classes.nameCell}>
                            <Avatar className={classes.avatar} src={product?.store?.logo}>
                              {getInitials(product.store.name)}
                            </Avatar>
                            <div>
                              <Link
                                color="inherit"
                                component={RouterLink}
                                to="/management/stores/1"
                                variant="h6"
                              >
                                {product?.store?.name}
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <Typography color="error">No store</Typography>
                        )}
                      </TableCell>
                      <TableCell> {product.name} </TableCell>
                      <TableCell> {product.price} </TableCell>
                      <TableCell>
                        <ProductImageWithDesign product={product} />
                      </TableCell>
                      <TableCell> {product.createdAt} </TableCell>
                      <TableCell>{product.updatedAt}</TableCell>
                      <TableCell align="right">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={'/'}
                          variant="outlined"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={products.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <TableEditBar
        selected={selectedStores}
        onActivate={handleActivate}
        onInactivate={handleInactivate}
        onDelete={handleDelete}
      />
    </div>
  );
};

StoreProductsTable.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array,
};

StoreProductsTable.defaultProps = {
  products: [],
};

export default StoreProductsTable;
